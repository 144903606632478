import Vue from "vue";
import "./plugins/axios";
import App from "./App.vue";
import router from "./router";
import store from "./store";
import "./plugins/vant.js";
import 'lib-flexible'
// 使用 vue-meta
import Meta from "vue-meta";
Vue.use(Meta);

// 工具方法
import util from '@/utils/util'
import queryString from 'query-string'
Vue.prototype.$queryString = queryString
Vue.prototype.$util = util

import commonHeader from '@/components/header/common'

// 页面固定参数
// import commonMixin from '@/mixins/common'
// Vue.mixin(commonMixin)

Vue.component("commonHeader", commonHeader);//全局自定义组件
new Vue({
    router,
    store,
    render: h => h(App)
}).$mount("#app");
