import axios from 'axios'
import store from "../store";
import {getToken, removeToken, getRequestAppType} from '@/cookies/auth'

axios.defaults.headers['Content-Type'] = 'application/json;charset=utf-8'
const baseURL = process.env.NODE_ENV === 'development' ? '/api/pintuan/api/wap/v1/' : '/pintuan/api/wap/v1/'
// 创建axios实例
const service = axios.create({
    // axios中请求配置有baseURL选项，表示请求URL公共部分
    baseURL: baseURL,

    // 超时
    timeout: 30000,
    // 请求方式
    method: 'get'
})
// request拦截器
service.interceptors.request.use(config => {

    // 是否需要设置 token
    const isToken = (config.headers || {}).isToken === false
    if (getToken() && !isToken) {
        config.headers['Authorization'] = 'Bearer ' + getToken() // 让每个请求携带自定义token 请根据实际情况自行修改
    }

    // 如果cookie内存有当前缓存类型字段，则更改请求header中app的值
    const headerApp = getRequestAppType() || store.state.device.deviceAppClient || ''
    if (headerApp) {
        config.headers['app'] = headerApp
    }

    // app内打开的品牌
    if (store.state.device.deviceAppBrand) {
        config.headers['brand'] = store.state.device.deviceAppBrand
    }

    return config
}, error => {
    console.log(error)
    Promise.reject(error)
})

// 响应拦截器
service.interceptors.response.use(res => {
        // 未设置状态码则默认成功状态
        const code = res?.data?.status;
        if (code === -1) {
            removeToken()
            return res.data

        } else if (code === 500) {

            return res.data

        } else if (!code && code !== 0) {

            return res.data

        } else {

            return res.data
        }
    },
    error => {
        console.log('err' + error)
        // this.$toast("res.message")
        let {message} = error;
        if (message == "Network Error") {
            message = "后端接口连接异常";
        } else if (message.includes("timeout")) {
            message = "系统接口请求超时";
        } else if (message.includes("Request failed with status code")) {
            message = "系统接口" + message.substr(message.length - 3) + "异常";
        }
        console.log(message);
        return Promise.reject(error)
    }
)

export default service
