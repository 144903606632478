<template>
  <div>
    <van-popover
        v-model="showPopover"
        trigger="click"
        placement="bottom-end">
      <template #reference>
        <img src="../../assets/images/header/header_menu.png" class="nav-bar-icon">
      </template>

      <template #default>
        <div class="menu-list">
          <div class="item" v-for="(item,key) in listFilter " :key="key" @click.stop="menuClick(item.routerName)">
            <img :src="menuURL(item.icon)" class="icon">
            <span class="text">{{ item.text }}</span>
          </div>
        </div>
      </template>
    </van-popover>

  </div>
</template>

<script>

export default {
  data() {
    return {
      showPopover: false,
      list: {
        home: {
          text: '首页',
          routerName: 'home',
          icon: 'menu_home'
        },
        // order: {
        //   text: '我的订单',
        //   routerName: 'orderList',
        //   icon: 'menu_order'
        // },
        // kefu: {
        //   text: '在线客服',
        //   routerName: 'kefu',
        //   icon: 'menu_kefu'
        // },
        login: {
          text: '登录',
          routerName: 'login',
          icon: 'menu_login'
        },
        logout: {
          text: '退出登录',
          routerName: 'logout',
          icon: 'menu_logout'
        }
      },
      dialogueURL: ''
    }
  },
  computed: {
    menuURL() {
      return function (name) {
        return require(`@/assets/images/header/${name}.png`)
      }
    },

    listFilter() {
      let result = {},
          isLogin = !!this.$store.state.user.token

      for (const key in this.list) {
        if (isLogin && key === 'login' || !isLogin && key === 'logout') {
          continue;
        }
        // app内打开时不显示登录和退出按钮
        if (this.$store.getters.isDeviceAppClient && (key === 'login' || key === 'logout')) {
          continue;
        }
        result[key] = this.list[key]
      }

      return result
    }
  },
  mounted() {
    this.$store.dispatch('getDialogueURL').then((dialogueURL) => {
      this.dialogueURL = dialogueURL
    })
  },
  methods: {
    // 菜单点击
    menuClick(routerName) {
      if (routerName === 'logout') {
        this.logout()
        return
      }
      if (routerName === 'kefu') {
        this.$util.locationHref(this.dialogueURL)
        return;
      }
      if (this.$route.name === routerName) {
        this.showPopover = false
        return;
      }

      this.$router.push({name: routerName})
    },
    // 退出登录
    logout() {
      this.$store.dispatch('setUserToken', '').then((res) => {
        this.$router.push({
          name: 'home'
        })
      })
    }
  }
}
</script>

<style scoped lang="scss">
.menu-list {
  //padding-top: .25rem;
  //padding-bottom: .25rem;

  .item {
    display: flex;
    align-items: center;
    width: 3.73rem;
    padding-left: .64rem;
    box-sizing: border-box;
    height: .59rem;
    margin-top: .5rem;
    margin-bottom: .5rem;

    .icon {
      width: .59rem;
      height: .59rem;
      margin-right: .27rem;
    }

    .text {
      font-size: 0.4rem;
      color: #18191A;
    }
  }
}
</style>
