module.exports = {
    data () {
        return {
            fromPath: ''
        }
    },
    created () {
        let historyKey = window.history?.state?.key
        this.$store?.dispatch('setPageHistory', {
            key: historyKey,
            url: window.location.href
        })
    },
    methods: {

    },

}
