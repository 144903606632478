// 判断app内打开的品牌，一般只有app才会存这个字段
import Cookies from "js-cookie";

const APP_BRAND = 'App-Brand'

export function getDeviceAppBrand() {
    return Cookies.get(APP_BRAND)
}

// 当前打开网页的设备，一般只有app才会存这个字段
const APP_CLIENT = 'App-Client'
export function getDeviceAppClient() {
    return Cookies.get(APP_CLIENT)
}

// 用户设备版本
const APP_VERSION = 'App-Version'
export function getDeviceAppVersion() {
    return Cookies.get(APP_VERSION)
}

// 判断是否是APP内打开
export function isDeviceAppClient() {
    return getDeviceAppClient() === 'app' || getDeviceAppClient() === 'pad'
}