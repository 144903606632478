// 微信浏览器
function isWXBrowser () {
    var ua = window.navigator.userAgent.toLowerCase();
    if (ua.match(/MicroMessenger/i) == 'micromessenger') {
        return true
    } else {
        return false
    }
}


//QQ浏览器
function isQQBrowser () {
    var ua = navigator.userAgent;
    return /(iPad|iPhone|iPod).*? (IPad)?QQ\/([\d]+)/.test(ua) || /\bV1_AND_SQI?_([\d]+)(.*? QQ\/([\d]+))?/.test(
        ua);
}

function isIOSBrowser () {
    return !!navigator.userAgent.match(/iPhone|iPad|iPod|Mac/i);
}

module.exports = {
    isWXBrowser,
    isIOSBrowser,
    isQQBrowser
}
