const { isQQBrowser } = require('./browser')
// 清除价格零
const clearPriceZero = (old) => {
	if (!old) return old
	//拷贝一份 返回去掉零的新串
	let newstr = old.toString()
	//循环变量 小数部分长度
	var leng = old.length - old.indexOf('.') - 1
	//判断是否有效数
	if (old.indexOf('.') > -1) {
		//循环小数部分
		for (let i = leng; i > 0; i--) {
			//如果newstr末尾有0
			if (
				newstr.lastIndexOf('0') > -1 &&
				newstr.substr(newstr.length - 1, 1) == 0
			) {
				var k = newstr.lastIndexOf('0')
				//如果小数点后只有一个0 去掉小数点
				if (newstr.charAt(k - 1) == '.') {
					return newstr.substring(0, k - 1)
				} else {
					//否则 去掉一个0
					newstr = newstr.substring(0, k)
				}
			} else {
				//如果末尾没有0
				return newstr
			}
		}
	}
	return old
}

// 不足两位数时，零填充
function zeroFill (number) {
	return number.toString().length > 1 ? number : '0' + number
}

// 获取html字符串body内容
function getHtmlStringBodyContent (htmlString) {
	var rx = /<body[^>]*>([\s\S]+?)<\/body>/i ///
	var m = rx.exec(htmlString)
	return m ? m[1] : ''
}

function getGoodsDetailURL (query) {
	let result = ''
	if (query) {
		Object.keys(query).forEach((key) => {
			result += key + '=' + query[key] + '&'
		})
		result = result.substring(0, result.length - 1)
	}
	return window.location.origin + '/wap/' + 'goods/detail?' + result
}

function getLocationURL (options = {}) {
	let result = '',
		query = options?.query

	if (query) {
		Object.keys(query).forEach((key) => {
			result += key + '=' + query[key] + '&'
		})
		result = result.substring(0, result.length - 1)
	}

	let url = window.location.origin + '/wap' + options.path

	if (query) {
		url += '?' + result
	}
	return url
}
function setWxShare (options = {}) {
	let { title, desc, link, imgUrl } = { ...options }
	wx.ready(function () {   //需在用户可能点击分享按钮前就先调用
		let options = {
			title: title || '金标尺拼团', // 分享标题
			desc: desc || '我发现了一个不错的商品，快来一起拼团吧。', // 分享描述
			link: link || window.location.origin, // 分享链接，该链接域名或路径必须与当前页面对应的公众号JS安全域名一致
			imgUrl: imgUrl || 'https://appresource-1253756937.cos.ap-chengdu.myqcloud.com/skb/wv/pingtuan/share.png', // 分享图标
		}
		console.log(options);
		wx.updateAppMessageShareData(options)
		wx.updateTimelineShareData(options)
	});
}

function setShare (param) {
	const wxParam = {}
	param?.title && (wxParam.title = param.title)
	param?.desc && (wxParam.desc = param.desc)
	param?.imgUrl && (wxParam.imgUrl = param.imgUrl)
	param?.link && (wxParam.link = param.link)
	setWxShare(wxParam)
	console.log(wxParam);
	if (isQQBrowser()) {
		const qqParam = {}
		param?.title && (qqParam.title = param.title)
		param?.desc && (qqParam.summary = param.desc)
		param?.imgUrl && (qqParam.pic = param.imgUrl)
		param?.link && (qqParam.url = param.link)
		window.setShareInfo && window.setShareInfo(qqParam)
	}
}
// 修改页面url
function replacePageState (param, text, url) {
	history.replaceState(param, text, url)
}

// 跳转url
function locationHref (url) {
	if (!url) { return }
	window.location.href = url
}

module.exports = {
	clearPriceZero,
	getHtmlStringBodyContent,
	zeroFill,
	getGoodsDetailURL,
	getLocationURL,
	setShare,
	replacePageState,
	locationHref
}
