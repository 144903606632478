import request from '@/utils/request'

// 登录方法
export function login (data) {
    return request({
        url: '/account/login',
        method: 'post',
        data: data,
    })
}

// 获取验证码
export function getCodeText (data) {
    return request({
        url: '/sms/send',
        method: 'post',
        data: data,

    })
}

//用户密码重置  
export function accountResetpwd (data) {
    return request({
        url: '/account/resetpwd',
        method: 'post',
        data: data,
    })
}

// 用户注册
export function accountRegister (data) {
    return request({
        url: '/account/register',
        method: 'post',
        data: data,
    })
}

// 获取客服链接
export function getDialogueURL (data) {
    return request({
        url: '/config/getCustomerUrl',
        method: 'post',
        data
    })
}


export function getProvinceInfor (data) {
    return request({
        url: '/config/get',
        method: 'post',
        data
    })
}

export function setHomeProvince (data) {
    return request({
        url: '/home/setDefault',
        method: 'post',
        data
    })
}


export function getWxShareInfo (data) {
    return request({
        url: '/config/getWxShareInfo',
        method: 'post',
        data
    })
}




