import { getDeviceAppBrand, getDeviceAppClient, getDeviceAppVersion } from "@/cookies/device";

const device = {
    state: {
        deviceAppClient: getDeviceAppClient(),//判断是否是app内打开
        deviceAppBrand: getDeviceAppBrand(),//app内打开的品牌
        deviceAppVersion: getDeviceAppVersion()//当前app版本
    }
}
export default device
