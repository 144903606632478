<template>
  <div class="wapper">
    <router-view :key="$route.path" />
  </div>
</template>

<script>
import { getWxShareInfo } from '@/api/user'
import commonMixins from "@/mixins/common";
import { getProvince } from "@/cookies/setup";
import VConsole from 'vconsole'

export default {
  name: 'app',
  components: {},
  mixins: [commonMixins],
  created() {
    getWxShareInfo({ url: window.location.href }).then((res) => {
      let packages = res.data.package
      window.wx.config({
        debug: false, // 开启调试模式,调用的所有api的返回值会在客户端alert出来，若要查看传入的参数，可以在pc端打开，参数信息会通过log打出，仅在pc端时才会打印。
        appId: packages.appId, // 必填，公众号的唯一标识
        timestamp: packages.timestamp, // 必填，生成签名的时间戳
        nonceStr: packages.nonceStr, // 必填，生成签名的随机串
        signature: packages.signature, // 必填，签名
        jsApiList: ['updateAppMessageShareData', 'updateTimelineShareData'], // 必填，需要使用的JS接口列表

      })
      // 默认调用一次分享
      this.$util.setShare()
    })
    // app内打开时，自动更改当前地区
    if (this.$store.getters.isDeviceAppClient && getProvince()) {
      this.$store.dispatch('setProvinceKey', getProvince()).then(() => {

      })
    }
    // document.title = "邀您参与金标尺课程拼团，精品好课限时超低价"

    // 测试服和本地显示打印
    if (window.location.href.indexOf('test') !== -1) {
      new VConsole()
    }

  },
}
</script>

<style scoped lang="scss">
.wapper {
  @include margin_center();
  background: #f7f8fa;
  min-height: 100vh;
  max-width: $maxWidth !important;
}

::v-deep {
  .reset-style {
    .van-tab__text {
      font-size: 0.4rem;
      color: #22242e;
    }

    .van-tabs__wrap {
      height: 1.2rem;
    }

    .van-tab {
      padding-bottom: 0.27rem;
      box-sizing: border-box;
    }

    .van-tab--active {
      .van-tab__text {
        font-size: 0.48rem;
        //font-weight: bold;
      }
    }

    .van-tabs__line {
      width: 0.43rem;
      height: 0.11rem;
      background: #ff6a4d;
      bottom: 0.2rem;
    }

    .van-tabs__nav--line {
      padding-bottom: 0;
    }
  }

  .van-empty__description {
    color: #919499;
  }

  @media screen and (min-width: $maxWidth) {
    .van-sticky--fixed {
      left: 50%;
      width: $maxWidth;
      transform: translateX(-50%);
    }
  }
}

@media screen and (min-width: $maxWidth) {
  ::v-deep .van-overlay {
    left: 50%;
    width: $maxWidth;
    transform: translateX(-50%);
  }

  ::v-deep .van-popup {
    left: 50%;
    width: $maxWidth;
    transform: translateX(-50%);
  }
}

@font-face {
  font-family: DIN-Medium;
  src: url('./assets/fonts/DIN-Medium.otf') format('otf');
  font-weight: normal;
  font-style: normal;
}

//@font-face {
//  font-family: PINGFANG;
//  src: url("./assets/fonts/PINGFANG.TTF");
//  font-weight: normal;
//  font-style: normal;
//}
</style>
