<template>
  <div>
    <div class="nav-bar van-row--flex van-row--justify-center van-cell--center" v-if="showHeader">
      <div class="nav-bar__left van-row--flex" style="flex: 1">

        <template v-if="isDeviceApp">
          <!--首页地区-->
          <div class="nav-bar__app-home van-row--flex van-cell--center" @click="toHome">
            <img src="../../assets/images/header/header_home.png" class="home-icon">
            <span class="text">首页</span>
          </div>

        </template>

        <!--返回按钮-->
        <div class="nav-bar__back " @click="clickBack" v-if="showBack && !isDeviceApp">
          <img src="../../assets/images/header/header_back.png" class="nav-bar-icon">
        </div>

        <!--首页地区-->
        <div class="nav-bar__area van-row--flex van-cell--center" v-if="showArea && areaText && !isDeviceApp"
          @click="toArea">
          <img src="../../assets/images/header/area_local.png" class="icon-local">
          <span class="text">{{ areaText }}</span>
        </div>
      </div>

      <div class="nav-bar__right van-row--flex van-cell--center">
        <!--登录文字按钮-->
        <div class="nav-bar__login" @click="clickLogin" v-if="showLoginText && !isLogin && !isDeviceApp">登录</div>

        <!--订单按钮 app外-->
        <div class="nav-bar__order" v-if="isLogin && showOrder && !isDeviceApp" @click="clickOrder">
          <img :src="menuURL('menu_order')" class="icon">
          <span class="text">订单</span>
        </div>

        <!--搜索按钮-->
        <div class="nav-bar__search" v-if="showSearch" @click="clickSearch">
          <img src="../../assets/images/header/header_search.png" class="nav-bar-icon">
        </div>

        <!--客服按钮-->
        <div class="nav-bar__dialogue" v-if="!!showDialogue && customer.length > 0" @click="clickDialogue">
          <img :src="require('@/assets/images/common/kefu.png')" class="icon">
        </div>

        <!--订单按钮 app内-->
        <div class="nav-bar__order" v-if="isLogin && showOrder && isDeviceApp" @click="clickOrder">
          <img :src="menuURL('menu_order')" class="icon">
          <span class="text">订单</span>
        </div>
        <div class="nav-bar__menu" v-if="!isDeviceApp">
          <!--顶部菜单-->
          <headerMenu></headerMenu>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import headerMenu from "@/components/header/common-menu";
import { isQQBrowser } from '@/utils/browser'

export default {
  props: {
    showHeader: {
      type: Boolean,
      default: true
    },
    showBack: {
      type: Boolean,
      default: true
    },
    backURL: {
      type: String,
      default: ''
    },
    showSearch: {
      type: Boolean,
      default: false
    },
    showArea: {
      type: Boolean,
      default: false
    },
    showLoginText: {
      type: Boolean,
      default: false
    },
    showDialogue: {
      type: Boolean,
      default: false
    },
    showOrder: {
      type: Boolean,
      default: false
    },
    customer: {
      type: String,
      default: ''
    }
  },
  components: {
    headerMenu
  },
  data () {
    return {
      province: '',
      province_list: {},
    };
  },
  computed: {
    isLogin () {
      return !!this.$store.state.user.token
    },
    isDeviceApp () {
      return this.$store.getters.isDeviceAppClient
    },
    menuURL () {
      return function (name) {
        return require(`@/assets/images/header/${name}.png`)
      }
    },
    areaText () {
      return this.province_list[this.province]?.name || ''
    },
  },
  mounted () {
    this.getCurrentProvince()
    this.$store.dispatch('getProvinceList').then(provinceList => {
      this.province_list = provinceList
    })
  },

  methods: {
    clickBack () {
      if (!this.backURL) {
        this.otherBack()

      } else {

        if (isQQBrowser()) {
          this.$util.locationHref(this.backURL)
        } else {
          window.location.replace(this.backURL)
        }
      }
    },

    otherBack () {
      this.$store.dispatch('getPageHistory').then((val) => {
        if (window.history?.state?.key !== val) {
          window.history.back()
          return;
        }

        let location = { path: '/' }
        if (isQQBrowser()) {
          this.$util.locationHref(this.$util.getLocationURL(location))
        } else {
          this.$router.replace(location).catch(() => {
          })
        }

      })
    },

    clickLogin () {
      this.$router.push({ name: 'login' })
    },
    clickSearch () {
      this.$router.push({ path: '/search/list' }).catch(() => {
      })
    },
    clickDialogue () {
      let customer = this.customer
      if (customer) {
        this.$util.locationHref(customer)
        return
      }
      this.$store.dispatch('getDialogueURL').then((dialogueURL) => {
        this.$util.locationHref(dialogueURL)
      })
    },
    toArea () {
      this.$router.push({ name: 'selectArea' })
    },
    toHome () {
      this.$util.locationHref('/')
    },
    clickOrder () {
      this.$router.push({ name: 'orderList' })
    },
    // 获取当前省份
    getCurrentProvince () {
      let province = this.$route.query.province
      return new Promise((resolve) => {
        if (province) {
          this.province = province
          resolve()
          return
        }

        this.$store.dispatch('getProvinceKey').then((province) => {
          this.province = province
          resolve()
        })
      })
    },
  },
}
</script>

<style scoped lang="scss">
.nav-bar {
  padding: .27rem .43rem;
  background-color: #fff;

  &__menu {
    margin-left: .2rem;
  }

  &__login {
    font-size: 0.4rem;
    color: #18191A;
  }

  &__search {
    margin-left: .3rem;

  }

  &__order {
    display: flex;
    align-items: center;
    font-size: 0.37rem;
    color: #3C3D42;
    margin-left: .1rem;

    .icon {
      width: .59rem;
      height: .59rem;
    }
  }

  &__area {
    .icon-local {
      width: .64rem;
      height: .64rem;
    }

    .icon-arrow {
      width: .19rem;
      height: .11rem;
      margin-left: .1rem;
    }

    .text {
      font-size: 0.4rem;
      color: #18191A;
    }
  }

  &__app-home {
    .home-icon {
      width: .5rem;
      transform: translateY(-.02rem);
    }

    .text {
      font-size: 0.37rem;
      color: #3C3D42;
      margin-left: .1rem;
    }
  }

  &__dialogue {
    display: flex;
    align-items: center;
    width: .45rem;
    margin-right: .1rem;
    margin-left: .2rem;
  }

  ::v-deep .nav-bar-icon {
    width: .64rem;
    height: .64rem;
    vertical-align: middle;
  }
}
</style>
