import Cookies from 'js-cookie'

const PROVINCE_KEY = "province-name"

export function getProvince() {
    return Cookies.get(PROVINCE_KEY)
}

export function setProvince(val) {
    return Cookies.set(PROVINCE_KEY, val, {expires: 7})
}

export function removeProvince() {
    return Cookies.remove(PROVINCE_KEY)
}

const CUSTOMER_KEY = 'customer-name'

export function getCustomer() {
    return Cookies.get(CUSTOMER_KEY)
}

export function setCustomer(val) {
    return Cookies.set(CUSTOMER_KEY, val, {expires: 7})
}

export function removeCustomer() {
    return Cookies.remove(CUSTOMER_KEY)
}

// 商品动态展示下标
const GOODS_RECORD_LOG_INDEX = 'goods-record-log'

export function getGoodsRecordLogIndex() {
    return Cookies.get(GOODS_RECORD_LOG_INDEX)
}

export function setGoodsRecordLogIndex(val) {
    return Cookies.set(GOODS_RECORD_LOG_INDEX, val, {expires: 7})
}
