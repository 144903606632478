const routes = [
  {
    path: "/",
    name: "home",
    component: () => import("../views/wap/home"),
    meta: { title: "金标尺拼团" }
  },
  {
    path: "/login",
    name: "login",
    component: () => import("../views/wap/user/login.vue"),
    meta: { title: "登录" }
  },
  {
    path: "/login/reset",
    name: "loginReset",
    component: () => import("../views/wap/user/login-reset.vue"),
    meta: { title: "重置密码" }
  },
  {
    path: "/login/register",
    name: "loginRegister",
    component: () => import("../views/wap/user/login-register.vue"),
    meta: { title: "完善资料" }
  },
  {
    path: "/select/area",
    name: "selectArea",
    component: () => import("../views/wap/select/area"),
    meta: { title: "选择地区",  }
  },
  {
    path: "/order/list",
    name: "orderList",
    component: () => import("../views/wap/order/list.vue"),
    meta: { title: "我的订单", requireAuth: true }
  },
  {
    path: "/order/detail",
    name: "orderDetail",
    component: () => import("../views/wap/order/detail.vue"),
    meta: { title: "开团详情" }
  },
  {
    path: "/goods/detail",
    name: "goodsDetail",
    component: () => import("../views/wap/goods/detail.vue"),
    meta: { title: "商品详情" }
  },
  {
    path: "/payment/result",
    name: "paymentResult",
    component: () => import("../views/wap/payment/result"),
    meta: { title: "交易结果", requireAuth: true }
  },
  {
    path: "/search/list",
    name: "searchList",
    component: () => import("../views/wap/search/list"),
    meta: { title: "搜索" }
  },
  {
    path: "/payment/pay",
    name: "paymentPay",
    component: () => import("../views/wap/payment/pay"),
    meta: { title: "确认订单", requireAuth: true }
  },
  {
    path: "/address/list",
    name: "addressList",
    component: () => import("../views/wap/address/list.vue"),
    meta: { title: "收货地址", requireAuth: true }
  },
  {
    path: "/address/edit",
    name: "addressEdit",
    component: () => import("../views/wap/address/edit.vue"),
    meta: { title: "地址编辑", requireAuth: true }
  }
];

export default routes;
