const address = {
    state: { select_id: 0 },
    mutations: {
        SET_SELECT_ID: (state, id) => {
            state.select_id = id
        },
    },
    actions: {
        setAddressSelectId ({ commit }, id) {
            commit('SET_SELECT_ID', id)
        },

    }
}
export default address