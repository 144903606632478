import {login} from '@/api/user'
import {getToken, setToken} from '@/cookies/auth'

const user = {
    state: {
        token: getToken(),
    },

    mutations: {
        SET_TOKEN: (state, token) => {
            state.token = token
        },

    },

    actions: {
        // 登录
        Login({commit}, userInfo) {
            return new Promise((resolve, reject) => {
                login(userInfo).then(res => {
                    if (res?.data?.token) {
                        // 写入cookies
                        setToken(res.data.token)
                        // 写入store
                        commit('SET_TOKEN', res.data.token)
                    }

                    resolve(res)
                }).catch(error => {
                    reject(error)
                })
            })
        },

        setUserToken({commit}, token) {
            // 写入cookies
            setToken(token)
            // 写入store
            commit('SET_TOKEN', token)
        },



        // 获取用户信息
        // GetInfo ({ commit, state }) {
        //   return new Promise((resolve, reject) => {
        //     getInfo(state.token).then(res => {
        //       const user = res.user
        //       const avatar = user.avatar == "" ? require("@/assets/image/profile.jpg") : process.env.VUE_APP_BASE_API + user.avatar;
        //       if (res.roles && res.roles.length > 0) { // 验证返回的roles是否是一个非空数组
        //         commit('SET_ROLES', res.roles)
        //         commit('SET_PERMISSIONS', res.permissions)
        //       } else {
        //         commit('SET_ROLES', ['ROLE_DEFAULT'])
        //       }
        //       commit('SET_NAME', user.userName)
        //       commit('SET_AVATAR', avatar)
        //       resolve(res)
        //     }).catch(error => {
        //       reject(error)
        //     })
        //   })
        // },

        // // 退出系统
        // LogOut ({ commit, state }) {
        //   return new Promise((resolve, reject) => {
        //     logout(state.token).then(() => {
        //       commit('SET_TOKEN', '')
        //       commit('SET_ROLES', [])
        //       commit('SET_PERMISSIONS', [])
        //       removeToken()
        //       resolve()
        //     }).catch(error => {
        //       reject(error)
        //     })
        //   })
        // },

        // 前端 登出
        // FedLogOut({commit}) {
        //     return new Promise(resolve => {
        //         commit('SET_TOKEN', '')
        //         removeToken()
        //         resolve()
        //     })
        // }
    }
}

export default user
