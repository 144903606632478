import Vue from 'vue'
import VueRouter from 'vue-router'
import routes from './routes'

import {getToken} from '@/cookies/auth'

Vue.use(VueRouter)

// 品牌省份白名单
const brandProvinceWhiteList = ['emptyContent', 'mpMiddle']

const router = new VueRouter({
    base: '/wap/',
    mode: 'history',
    routes,
})

router.beforeEach((to, from, next) => {

    // 刷新页面标题
    if (to.meta.title) {
        document.title = to.meta.title
    }

    // 当登录失效时跳转登录页
    if (to.meta.requireAuth && !getToken()) {
        next({name: 'login', query: to.query})
        return
    }

    // // 当目标地址不在省份品牌参数白名单时
    // if (brandProvinceWhiteList.indexOf(to.name) <= -1) {
    //   if (!to.query.brand || !to.query.province) {
    //     next({ name: 'emptyContent', query: { url: to.fullPath } })
    //     return
    //   }
    // }

    next()
})

export default router
