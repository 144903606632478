import { getDialogueURL, getProvinceInfor, setHomeProvince } from "@/api/user";
import { getCustomer, getProvince, setCustomer, setProvince } from "@/cookies/setup";
import { isDeviceAppClient } from "@/cookies/device";

const setup = {
    state: {
        provinceKey: '',
        dialogueURL: '',
        provinceList: null,//省份列表
        pageHistory: new Map(),//页面访问记录
        autoBuyList: new Map(),//用于记录未登录时点击购买，登录后自动跳转订单详情的商品id
    },
    mutations: {
        SET_PROVINCE_KEY: (state, key) => {
            // app时不再写入
            if (isDeviceAppClient()) {
                setProvince(key)
            }
            state.provinceKey = key
        },
        SET_PROVINCE_List: (state, key) => {
            state.provinceList = key
        },
        SET_DIALOGUE_URL: (state, url) => {
            setCustomer(url)
            state.dialogueURL = url
        },
        SET_HISTORY_LOGIN: (state, arr) => {
            state.pageHistory = arr
        },
        SET_AUTO_BUY_LIST: (state, o) => {
            let maps = state.autoBuyList
            maps.set(o.id, o)
            state.autoBuyList = maps
        }
    },
    actions: {

        setAutoBuyList({ commit }, o) {
            commit('SET_AUTO_BUY_LIST', o)
        },
        // 获取省份key
        getProvinceKey({ commit }) {
            let cookiesProvince = getProvince()
            return new Promise((resolve, reject) => {

                if (cookiesProvince) {
                    resolve(cookiesProvince)
                    return
                }
                getProvinceInfor().then(res => {
                    commit('SET_PROVINCE_KEY', res.data.default_province)
                    resolve(res.data.default_province)
                }).catch(error => {
                    reject(error)
                })
            })
        },
        // 设置省份key
        setProvinceKey({ commit }, key) {
            return new Promise((resolve, reject) => {
                setHomeProvince({ province: key }).then(res => {
                    commit('SET_PROVINCE_KEY', key)
                    resolve(res)
                }).catch(error => {
                    reject(error)
                })
            })
        },
        // 获取省份列表
        getProvinceList({ commit, state }) {
            return new Promise((resolve) => {
                if (state.provinceList) {
                    return resolve(state.provinceList)
                }
                getProvinceInfor().then(res => {
                    let province_list = res.data.province_list || null
                    commit('SET_PROVINCE_List', province_list)
                    resolve(province_list || {})
                })
            })
        },
        // 获取客服地址
        getDialogueURL({ commit, state }) {
            return new Promise((resolve, reject) => {
                let url = state.dialogueURL || getCustomer()
                if (url) {
                    resolve(url)
                    return
                }

                getDialogueURL().then(res => {
                    commit('SET_DIALOGUE_URL', res.data.customer_url)
                    resolve(state.dialogueURL)
                })
            })
        },
        setPageHistory({ commit, state }, o) {
            let arrMap = state.pageHistory
            arrMap.set(o.key, o.url)
            commit('SET_HISTORY_LOGIN', arrMap)
        },
        getPageHistory({ state }) {
            let arrMap = state.pageHistory,
                result = ''
            for (let val of arrMap) {
                result = val[0]
                break
            }
            return result
        }
    }
}
export default setup
