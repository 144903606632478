import Vue from 'vue'
import Vuex from 'vuex'
import getters from './getters'
import user from './modules/user'
import address from './modules/address'
import setup from "@/store/modules/setup";
import device from "@/store/modules/device";

Vue.use(Vuex)
const store = new Vuex.Store({
    modules: {
        user,
        address,
        setup,
        device
    },
    ...getters
})

export default store
